import { useQuery } from '@tanstack/react-query';
import { request, gql } from 'graphql-request';

import { QueryKeys } from 'src/constants/queryKeys';

import { PoolToken } from '@app/types/pool';
import { getConfig } from '@app/config';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

type QueryParams = {
  tokenId: string | undefined;
};

type Response = {
  address: string;
  liquidity: string;
  price: string;
  tick: string;
  token0: PoolToken;
  token1: PoolToken;
};

function getQuery(queryParams: QueryParams) {
  return gql`
      query {
        token(id: "${queryParams.tokenId}") {
          id
          symbol
          name
          decimals
          derivedMatic
        }
      }
    `;
}

const fetcher = async (
  queryParams: QueryParams,
  chainId: number | undefined
) => {
  const query = getQuery(queryParams);

  const config = getConfig(chainId);

  const res = await request<{ token: PoolToken }>(
    `${config?.URLS.subgraphUrlV3}`,
    query,
    queryParams
  );

  return res.token;
};

export function useSingleTokenQuery(params: QueryParams) {
  const chainId = useSupportedChain()?.chainId;

  return useQuery<PoolToken | undefined>(
    [QueryKeys.SINGLE_TOKEN, { params, chainId }],
    () => {
      return fetcher(params, chainId);
    },
    {
      staleTime: 5000,
      refetchOnWindowFocus: false,
      enabled: !!params.tokenId
    }
  );
}
