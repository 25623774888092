import { Box, ChakraProps, VStack } from '@chakra-ui/react';
import { TransactionError } from '@thirdweb-dev/react';
import React, { FC, useState } from 'react';

import { ExternalLink } from '@app/components/ExternalLink';
import { NetworkMismatchButton } from '@app/components/NetworkMismatchButton';
import { HeaderControls } from '@app/components/Swap/components/HeaderControls';
import { QuickAmountButtons } from '@app/components/Swap/components/QuickAmountButtons';
import { SubmitSwapButton } from '@app/components/Swap/components/SubmitSwapButton/SubmitSwapButton';
import { SwapButton } from '@app/components/Swap/components/SwapButton';
import { SwapInfo } from '@app/components/Swap/components/SwapInfo';
import { SwapNotifications } from '@app/components/Swap/components/SwapNotifications';
import { TokenInput } from '@app/components/Swap/components/TokenInput';
import { useSwapTokens } from '@app/components/Swap/hooks';
import { showErrorToast, showSuccessToast } from '@app/components/Toast';
import { TransactionResultModal } from '@app/components/TransactionResultModal';
import { useSwap } from '@app/hooks/swap/useSwap';
import { useTokenBalance } from '@app/hooks/swap/useTokenBalance';
import { getCurrentTrade } from '@app/hooks/swap/useTokensRate';
import { Token } from '@app/types/token';
import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';

interface Props extends ChakraProps {
  initialTokensData: {
    from: Token;
    to: Token;
  };
}

export const Swap: FC<Props> = ({ initialTokensData, ...rest }) => {
  const [feedback, setFeedback] = useState<{
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  } | null>(null);
  const {
    state,
    handleFromTokenChange,
    handleFromTokenSelect,
    handleToTokenSelect,
    handleFlip,
    swapOut,
    isLoadingSwapOut,
    rate,
    isSwapAvailable,
    isSwapAvailabilityLoading,
    fetchRates,
    resetStateValues,
    pairAddress,
    setSelectedTrade,
    trades
  } = useSwapTokens(initialTokensData);

  const { swapTokens, loading: swapInProgress } = useSwap(
    state.from,
    state.to,
    swapOut,
    getCurrentTrade(state.selectedTrade, trades)
  );

  const { data: fromTokenBalance } = useTokenBalance(
    state.from.token?.contractAddress ?? ''
  );

  const isBalanceExceeded =
    fromTokenBalance && +fromTokenBalance.displayValue < +state.from.amount;

  return (
    <Box
      padding="12px"
      bg="sand"
      borderRadius={16}
      maxWidth="573px"
      mx="auto"
      w="100%"
      {...rest}
    >
      <VStack>
        <HeaderControls onRefresh={fetchRates} />
        <TokenInput
          label="You pay"
          showMax
          source="from"
          values={state.from}
          onChange={handleFromTokenChange}
          disabled={false}
          onTokenSelect={handleFromTokenSelect}
          filterByPools
        />
        <SwapButton onClick={handleFlip} />
        <TokenInput
          label="You receive"
          values={state.to}
          source="to"
          disabled={false}
          onTokenSelect={handleToTokenSelect}
          readOnlyValue={swapOut ?? ''}
          filterByPools
        />
        <QuickAmountButtons
          useThreshold={
            state.from.token.contractAddress === NATIVE_TOKEN_ADDRESS
          }
          amount={state.from.amount}
          balance={
            fromTokenBalance?.displayValue
              ? Number(fromTokenBalance.displayValue)
              : undefined
          }
          onUpdate={handleFromTokenChange}
        />
        <SwapInfo
          isSwapAvailable={isSwapAvailable}
          isSwapAvailabilityLoading={isSwapAvailabilityLoading}
          isLoadingSwapOut={isLoadingSwapOut}
          swapOut={swapOut}
          state={state}
          rate={rate}
          showInfo
          pairAddress={pairAddress}
          trades={trades}
          setSelectedTrade={setSelectedTrade}
        />
        <NetworkMismatchButton />
        <SubmitSwapButton
          state={state}
          isBalanceExceeded={isBalanceExceeded}
          isSwapAvailable={isSwapAvailable}
          swapInProgress={swapInProgress}
          onSubmit={async () => {
            try {
              const res = await swapTokens();

              if (res['reason'] !== undefined) {
                showErrorToast(res.reason);
              } else if (res instanceof Error) {
                showErrorToast(res.message);
              } else {
                resetStateValues();

                const txHash = res?.receipt?.transactionHash;

                showSuccessToast(
                  `Successfully swaped ${state.from.token?.symbol} to ${state.to.token?.symbol}`,
                  <ExternalLink txHash={txHash} />
                );

                setFeedback({
                  status: 'success',
                  title: 'Transaction Confirmed',
                  message: 'Your transaction has been successfully completed',
                  txHash
                });
              }
            } catch (e) {
              if (e instanceof TransactionError) {
                showErrorToast(e.reason);
              } else {
                showErrorToast(
                  `Error swap ${state.from.token?.symbol} to ${state.to.token?.symbol}`
                );
              }

              setFeedback({
                status: 'error',
                title: 'Transaction Error',
                message:
                  'There was an error processing your transaction. Please check the details and try again'
              });
            }
          }}
          isFromValueEmpty={
            !state.from.amount || Number(state.from.amount) === 0
          }
        />
        <SwapNotifications
          amount={state.from.amount}
          isBalanceExceeded={isBalanceExceeded}
          rate={rate}
          isLoading={isLoadingSwapOut}
        />
        <TransactionResultModal
          isOpen={!!feedback}
          status={feedback?.status}
          title={feedback?.title}
          message={feedback?.message}
          txHash={feedback?.txHash}
          onClose={() => setFeedback(null)}
        />
      </VStack>
    </Box>
  );
};
