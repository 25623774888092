import {
  ChevronDownIcon,
  ChevronUpIcon,
  InfoOutlineIcon
} from '@chakra-ui/icons';
import {
  Box,
  HStack,
  IconButton,
  Skeleton,
  Spinner,
  Text
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { useToggle } from 'react-use';

import { TradeRouting } from '@app/components/Swap/components/TradeRouting';
import { toOptionalFixed } from '@app/helpers/format';
import { getCurrentTrade } from '@app/hooks/swap/useTokensRate';
import { Token } from '@app/types/token';
import { USTrade } from '@app/types/trade';

interface Props {
  pairAddress: string | null;
  rate: string | undefined;
  isSwapAvailable: boolean;
  isSwapAvailabilityLoading: boolean;
  isLoadingSwapOut: boolean;
  swapOut: string | undefined;
  showInfo?: boolean;
  state: {
    from: {
      token: Token;
      amount: string;
    };
    to: {
      token: Token;
      amount: string;
    };
    selectedTrade: USTrade | undefined;
  };
  trades: USTrade[] | undefined;
  setSelectedTrade: (trade: USTrade) => void;
}

export const SwapInfo: FC<Props> = ({
  rate,
  isLoadingSwapOut,
  state,
  swapOut,
  showInfo,
  trades,
  setSelectedTrade
}) => {
  const [isOpen, toggleOpen] = useToggle(false);

  const currentTrade = getCurrentTrade(state.selectedTrade, trades);
  const priceImpact = currentTrade?.priceImpact.toFixed();

  function renderInfoRow(
    label: string,
    value: string | number | undefined,
    loading?: boolean
  ) {
    return (
      <HStack justifyContent="space-between" mb={2}>
        <Text fontWeight={400} fontSize="14px" color="whiteOpacity.70">
          {label}
        </Text>
        {loading ? (
          <Skeleton
            height="16px"
            width="64px"
            startColor="neutral.700"
            endColor="neutral.800"
            mb="2px"
          />
        ) : (
          <Text fontWeight={400} fontSize="14px" color="white">
            {value}
          </Text>
        )}
      </HStack>
    );
  }

  if (isLoadingSwapOut) {
    return (
      <Box bg="transparent" width="100%" p={2} mt="8px">
        <HStack w="100%" justify="space-between">
          <Text color="neutral.300">Processing...</Text>
          <Spinner width={5} height={5} color="orange" />
        </HStack>
      </Box>
    );
  }

  if (+state.from.amount > 0 && rate) {
    return (
      <Box bg="transparent" width="100%" p={2} mt="8px">
        <HStack justifyContent="space-between">
          <HStack>
            <InfoOutlineIcon color="neutral.300" w="14px" h="14px" />
            <Text color="neutral.300">
              1 {state.from.token.symbol} = {Number(rate)}{' '}
              {state.to.token.symbol}
            </Text>
          </HStack>
          {showInfo && (
            <IconButton
              fontSize={16}
              onClick={toggleOpen}
              aria-label="Search database"
              icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            />
          )}
        </HStack>
        {isOpen && (
          <Box mt={4} borderTop="1px solid gray.500">
            {renderInfoRow(
              `${state.from.token.symbol} / ${state.to.token.symbol} rate`,
              `1 ${state.from.token.symbol} -> ${rate} ${state.to.token.symbol}`
            )}
            {renderInfoRow('Minimum received', swapOut)}
            {renderInfoRow(
              'Price impact',
              `${priceImpact ? `${toOptionalFixed(+priceImpact, 2)}%` : 'n/a'}`
            )}
            <TradeRouting
              data={trades}
              from={state.from}
              to={state.to}
              setSelectedTrade={setSelectedTrade}
              selectedTrade={state.selectedTrade}
            />
          </Box>
        )}
      </Box>
    );
  }

  return null;
};
