import {
  Box,
  Button,
  ChakraProps,
  HStack,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import FlexyLogo from 'src/assets/images/logo/flexy.svg';

import { TransactionResultModal } from '@app/components/TransactionResultModal';
import { Token } from '@app/types/token';
import { SwapPair } from '@app/components/SwapV3/components/SwapPair';
import { SwapParams } from '@app/components/SwapV3/components/SwapParams/SwapParams';
import { SwapButton } from '@app/components/SwapV3/components/SwapButton';
import { HeaderControlsV3 } from '@app/components/Swap/components/HeaderControls/HeaderControlsV3';
import { useInitialSwapSetup } from '@app/components/SwapV3/hooks';
import { Flexy } from '@flexy.tech/widget';
import '@flexy.tech/widget/style.css';
import { useSigner } from '@thirdweb-dev/react';

import s from './SwapV3.module.scss';
import { Scroll } from '@thirdweb-dev/chains';
import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import { useUserState } from '@app/state/userStore';

interface Props extends ChakraProps {
  initialTokensData: {
    from: Token;
    to: Token;
  };
}

export const SwapV3: FC<Props> = ({ initialTokensData, ...rest }) => {
  const [feedback, setFeedback] = useState<{
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  } | null>(null);

  const {
    bridgeMode,
    actions: { toggleBridgeMode }
  } = useUserState();

  useInitialSwapSetup(initialTokensData);

  const signer = useSigner();

  return (
    <Box
      padding="12px"
      bg="brand.black"
      borderRadius={16}
      maxWidth="573px"
      mx="auto"
      w="100%"
      {...rest}
      className={s.root}
    >
      <VStack>
        <HStack
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          gap={4}
        >
          <Button
            variant={!bridgeMode ? 'primary' : 'tertiary'}
            onClick={() => toggleBridgeMode(false)}
            minWidth="100px"
            height="42px"
            fontSize={16}
          >
            Swap
          </Button>
          <Button
            variant={bridgeMode ? 'primary' : 'tertiary'}
            onClick={() => {
              toggleBridgeMode(true);
            }}
            fontSize={16}
            height="42px"
            minWidth="100px"
          >
            Bridge
          </Button>
          <HeaderControlsV3 />
        </HStack>

        {!bridgeMode && (
          <>
            <Text
              fontSize={24}
              color="brand.primary"
              textAlign="left"
              width="100%"
            >
              Swap
            </Text>
            <SwapPair />
            <SwapButton />
            <SwapParams />
            <TransactionResultModal
              isOpen={!!feedback}
              status={feedback?.status}
              title={feedback?.title}
              message={feedback?.message}
              txHash={feedback?.txHash}
              onClose={() => setFeedback(null)}
            />
          </>
        )}
        {bridgeMode && (
          <>
            <HStack width="100%" justifyContent="flex-start">
              <FlexyLogo />
              <Text fontSize={24} color="brand.primary" textAlign="left">
                Bridge
              </Text>
            </HStack>
            <Flexy
              theme="dark"
              fontFamily="Roboto"
              accentColor="#0094FF"
              walletClientOrSigner={signer}
              renderMode="component"
              limitDestination={Scroll.chainId}
              preferredInputToken={NATIVE_TOKEN_ADDRESS}
            />
          </>
        )}
      </VStack>
    </Box>
  );
};
