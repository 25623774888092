import { useAddress, TransactionError } from '@thirdweb-dev/react';
import React, { FC } from 'react';

import { CustomConnectWalletButton } from '@app/components/CustomConnectWalletButton';
import { ExternalLink } from '@app/components/ExternalLink';
import { SubmitButton } from '@app/components/SubmitButton';
import { showErrorToast, showSuccessToast } from '@app/components/Toast';
import { useTokenApprove } from '@app/hooks/liquidity/useTokenApprove';
import { Token } from '@app/types/token';
import { useLocationContext } from '@app/context/LocationContext';
import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import { useConfig } from '@app/config';
import { useNetworkMismatch } from '@app/hooks/thirdweb/useNetworkMismatch';

interface Props {
  isSwapAvailable: boolean;
  swapInProgress: boolean;
  onSubmit: () => void;
  isFromValueEmpty: boolean;
  isBalanceExceeded: boolean | undefined;
  state: {
    from: {
      token: Token | null;
      amount: string;
    };
    to: {
      token: Token | null;
      amount: string;
    };
  };
}

export const SubmitSwapButton: FC<Props> = ({
  state,
  isSwapAvailable,
  swapInProgress,
  onSubmit,
  isFromValueEmpty,
  isBalanceExceeded
}) => {
  const { isReadOnly } = useLocationContext();
  const isMismatchedNetwork = useNetworkMismatch();
  const address = useAddress();
  const isConnected = !!address;
  const config = useConfig();

  const {
    requiresApproval: requiresApprovalFromToken,
    approve: approveFrom,
    isApproveInProgress: isApproveFromInProgress,
    getAllowance: getAllowanceFrom
  } = useTokenApprove(state.from);

  const isEthWethPair =
    (state.from.token?.contractAddress == config?.CONTRACTS.WETH ||
      state.from.token?.contractAddress === NATIVE_TOKEN_ADDRESS) &&
    (state.to.token?.contractAddress == config?.CONTRACTS.WETH ||
      state.to.token?.contractAddress === NATIVE_TOKEN_ADDRESS);

  if (isReadOnly) {
    return null;
  }

  if (!isConnected) {
    return <CustomConnectWalletButton />;
  }

  if (isMismatchedNetwork) {
    return null;
  }

  if (requiresApprovalFromToken && !isBalanceExceeded && !isEthWethPair) {
    return (
      <SubmitButton
        isLoading={isApproveFromInProgress}
        loadingText="Processing..."
        onClick={async () => {
          try {
            const res = await approveFrom();

            if (!(res instanceof TransactionError)) {
              await getAllowanceFrom();

              const txHash = res?.receipt?.transactionHash;

              showSuccessToast(
                `Successfully approved ${state.from.token?.symbol}`,
                <ExternalLink txHash={txHash} />
              );
            }
          } catch (e) {
            if (e instanceof TransactionError) {
              showErrorToast(e.reason);
            } else {
              showErrorToast(`Error approving ${state.from.token?.symbol}`);
            }
          }
        }}
        label={`Approve ${state.from.token?.symbol}`}
      />
    );
  }

  function getSubmitLabel() {
    if (isBalanceExceeded) {
      return 'Insufficient balance';
    }

    if (isFromValueEmpty) {
      return 'Enter token amount to swap';
    }

    return 'Proceed to swap';
  }

  if (!isMismatchedNetwork) {
    const _disabled =
      !isConnected || !isSwapAvailable || isBalanceExceeded || isFromValueEmpty;

    return (
      <SubmitButton
        disabled={_disabled}
        isLoading={swapInProgress}
        loadingText="Processing..."
        onClick={onSubmit}
        label={getSubmitLabel()}
      />
    );
  }

  return null;
};
