import { useQuery } from '@tanstack/react-query';
import { request, gql } from 'graphql-request';

import { QueryKeys } from 'src/constants/queryKeys';

import { PoolToken } from '@app/types/pool';
import { getConfig } from '@app/config';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

type Response = {
  address: string;
  liquidity: string;
  price: string;
  tick: string;
  token0: PoolToken;
  token1: PoolToken;
};

type Bundle = {
  id: string;
  maticPriceUSD: string;
};

function getQuery() {
  return gql`
    query {
      bundles {
        id
        maticPriceUSD
      }
    }
  `;
}

const fetcher = async (chainId: number | undefined) => {
  const query = getQuery();

  const config = getConfig(chainId);

  if (!config) {
    return null;
  }

  const res = await request<{ bundles: Bundle[] }>(
    `${config?.URLS.subgraphUrlV3}`,
    query
  );

  return res.bundles[0] ?? null;
};

export function useNativePriceQuery() {
  const chainId = useSupportedChain()?.chainId;

  return useQuery<Bundle | undefined | null>(
    [QueryKeys.NATIVE_PRICE, chainId],
    () => {
      return fetcher(chainId);
    },
    {
      staleTime: 5000,
      refetchOnWindowFocus: false,
      enabled: !!chainId
    }
  );
}
