import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/alert';
import React, { FC, useMemo } from 'react';

interface Props {
  isBalanceExceeded: boolean | undefined;
  rate: string | undefined;
  amount: string | number;
  isLoading?: boolean;
}

export const SwapNotifications: FC<Props> = ({
  isBalanceExceeded,
  rate,
  amount,
  isLoading
}) => {
  const error = useMemo(() => {
    if (isBalanceExceeded) {
      return 'Available balance exceeded';
    }

    if (!rate && Number(amount) > 0 && !isLoading) {
      return 'Swap is not available due to low input amount';
    }

    return null;
  }, [isBalanceExceeded, rate, amount, isLoading]);

  if (error) {
    return (
      <Alert
        status="error"
        display="flex"
        border="1px solid"
        borderColor="danger.800"
        mt="16px"
        borderRadius="10px"
        py="10px"
        px="12px"
      >
        <AlertIcon w="16px" h="16px" mr="8px" color="orange" />
        <AlertDescription color="danger.200">{error}</AlertDescription>
      </Alert>
    );
  }

  return null;
};
