import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Grid, HStack, Text, VStack } from '@chakra-ui/react';
import clsx from 'clsx';
import React, { FC } from 'react';

import { Icon } from '@app/components/Icon';
import { TokenIcon } from '@app/components/TokenIcon';
import { getCurrentTrade } from '@app/hooks/swap/useTokensRate';
import { Token as KToken } from '@app/types/token';
import { USTrade } from '@app/types/trade';

import s from './TradeRouting.module.scss';

interface Props {
  from: { token: KToken; amount: string };
  to: { token: KToken; amount: string };
  data: USTrade[] | undefined;
  setSelectedTrade: (trade: USTrade) => void;
  selectedTrade: USTrade | undefined;
}

export const TradeRouting: FC<Props> = ({
  data,
  // setSelectedTrade,
  from,
  to,
  selectedTrade
}) => {
  if (!data || !data.length) {
    return null;
  }

  function renderDivider() {
    return (
      <Box
        className={s.divider}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          position: 'relative',
          borderBottom: '1px dashed',
          borderColor: 'gray.4'
        }}
      >
        <Icon
          name="arrowRightFilled"
          style={{
            position: 'absolute',
            top: '-8px',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        />
      </Box>
    );
  }

  const currentTrade = getCurrentTrade(selectedTrade, data);

  return (
    <Box bg="transparent" width="100%" mt="8px">
      <HStack>
        <Text fontWeight={400} fontSize="14px" color="whiteOpacity.70">
          Trade routing
        </Text>
        <InfoOutlineIcon color="neutral.300" w="14px" h="14px" />
      </HStack>
      <Grid mt={4} gridTemplateColumns="64px 1fr 64px" alignItems="center">
        <VStack
          justifyContent="center"
          alignItems="center"
          height="64px"
          className={clsx({
            [s.fromIcon]: data?.length && data.length > 1
          })}
        >
          <TokenIcon
            symbol={from.token.symbol}
            iconUrl={from.token.logoUri}
            width="32px"
            height="32px"
          />
        </VStack>
        <VStack alignItems="space-around" gap="24px">
          {currentTrade && (
            <HStack
              justifyContent="space-around"
              gap="8px"
              minHeight="40px"
              // onClick={() => setSelectedTrade(currentTrade)}
              className={clsx(s.trade, {
                [s.selected]: selectedTrade && selectedTrade === currentTrade
              })}
            >
              {currentTrade.route.path
                .filter((token, i) => {
                  return i !== 0 && i !== currentTrade.route.path.length - 1;
                })
                .map(token => {
                  return (
                    <React.Fragment key={token.address}>
                      {renderDivider()}
                      <HStack
                        sx={{
                          padding: '8px',
                          borderRadius: '10px',
                          background: 'neutral.1000'
                        }}
                      >
                        <TokenIcon key={token.address} symbol={token.symbol} />
                        <Text>{token.name ?? token.symbol}</Text>
                      </HStack>
                    </React.Fragment>
                  );
                })}
              {renderDivider()}
            </HStack>
          )}
        </VStack>
        <VStack
          justifyContent="center"
          height="64px"
          className={clsx({
            [s.toIcon]: data?.length && data.length > 1
          })}
        >
          <TokenIcon
            symbol={to.token.symbol}
            iconUrl={to.token.logoUri}
            width="32px"
            height="32px"
          />
        </VStack>
      </Grid>
    </Box>
  );
};
