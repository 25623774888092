import React, { FC } from 'react';
import { Button, HStack } from '@chakra-ui/react';

interface Props {
  view: string;
  onChangeView: (val: string) => void;
  views: {
    label: string;
    value: string;
  }[];
}

const Toggle: FC<Props> = ({ view, onChangeView, views }) => {
  return (
    <HStack
      w={['100%', 'fit-content']}
      alignItems="flex-start"
      gap="3px"
      px="8px"
      py="8px"
      sx={{
        background: 'sand',
        border: '1px solid',
        borderColor: 'sand',
        borderRadius: '6px'
      }}
    >
      {views.map(item => {
        return (
          <Button
            key={item.value}
            variant="tertiary"
            onClick={() => {
              onChangeView(item.value);
            }}
            sx={{
              padding: '12px 24px',
              color: view === item.value ? 'orange' : 'black',
              bg: view === item.value ? 'white' : 'transparent'
            }}
            size="md"
          >
            {item.label}
          </Button>
        );
      })}
    </HStack>
  );
};

export default Toggle;
